<script setup lang="ts">
  import { PlanType, PolicyType, SnpType } from '~/generated/api-clients-generated'
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import type { PlanBannerConfig } from '~/composables/PlanBanners'
  import QuoteCardPolicyBadges from '~/components/quote/card/QuoteCardPolicyBadges.vue'

  const props = defineProps<{
    id: string
    quote: QuotedPlanVm
    banner?: Partial<PlanBannerConfig & { text: string }> | null | undefined
  }>()

  const { show } = DialogController.use()
  const { getId } = Id.use(props.id)
  const { flag } = FeatureFlags.use()

  const isMapd = computed(() => props.quote.type === PlanType.MAPD)
  const isDsnp = computed(() => props.quote.snpType === SnpType.DualEligible)

  const { getComponentContent, getTooltipContent, hydratePlaceholders } = Cms.use()

  const { content } = await getComponentContent('QuoteCard')

  const { content: starRatingTooltip } = await getTooltipContent('MedicareStarRating')

  const showPlanFitModal = computed(() => flag<boolean>('plan-fit-modal').value)

  const { banner } = await PlanBanners.use(props.quote.medicareId)

  const usedBanner = computed(() => props.banner ?? banner.value)

  const { devModeEnabled, openInConnecture, isHeatEnabled } = DevModeHelpers.use()
</script>

<template>
  <section
    :class="['header-banner', `${usedBanner?.color}`]"
    v-if="!!usedBanner || !!$slots.banner"
  >
    <div class="banner-content">
      <slot name="banner">
        <HpIcon v-if="!!usedBanner?.icon" :name="usedBanner.icon" class="-mt-1" />
        {{ usedBanner?.text }}
      </slot>
    </div>
  </section>
  <section class="header-plan-info">
    <div class="header-topline">
      <div class="topline-logo">
        <PxCarrierLogo
          :logo-key="quote.details.logoKey!"
          :plan-year="quote.details.year!"
          :logo-alt="`${quote.details.logoKey} carrier logo`"
        />
      </div>
      <template v-if="quote.recommendation.exists">
        <HpAnchor
          class="topline-plan-score cursor-pointer text-primary-900 no-underline"
          @click.prevent="show(getId(`score-calc-sidebar`))"
          v-if="isMapd && showPlanFitModal"
        >
          <QuotePlanScoreV2 :score="quote.score">
            <template #label>
              <QuoteCardScoreSidebar
                :id="getId(`score-calc-sidebar`)"
                :quote="quote"
                :label="content.ext.score_label"
              />
            </template>
          </QuotePlanScoreV2>
        </HpAnchor>
        <QuotePlanScoreV2 :score="quote.score" v-else-if="isMapd" class="-mt-3 text-primary-900">
          <template #label> Plan Fit</template>
        </QuotePlanScoreV2>
      </template>
    </div>
    <div class="header-plan-name">
      <h1>{{ quote.name }}</h1>
    </div>
    <div class="header-details">
      <QuoteBadge :text="quote.details.year!.toString()" variant="white" />
      <QuoteCardPolicyBadges :id="getId()" :quote="quote" />
      <PxStarRating
        :star-rating="quote.details.starRating"
        :tooltip="{
          id: getId(`star-rating-tooltip`),
          title: starRatingTooltip.title,
          body: hydratePlaceholders(starRatingTooltip.body, { planYear: quote.details.year })
        }"
      />
    </div>
  </section>

  <template v-if="devModeEnabled && isHeatEnabled(quote)">
    <div class="mb-5 flex w-full items-center justify-center gap-2">
      <HpIcon name="login" class="w-4 text-primary" />
      <HpAnchor class="text-sm font-bold" @click.prevent="openInConnecture(quote.externalId)"
        >Open app in Connecture
      </HpAnchor>
    </div>
  </template>
</template>

<style scoped>
  .header-plan-info {
    @apply flex w-full flex-col space-y-2 p-4;

    .header-topline {
      @apply flex w-full items-center justify-between;

      .topline-logo {
        @apply w-full max-w-[20rem];

        :deep(.ibx) {
          @apply max-w-[16rem] sm:max-w-[20rem];
        }

        :deep(.anthem-bc) {
          @apply max-w-[12rem] sm:max-w-[20rem];
        }

        :deep(.highmark) {
          @apply h-full;
        }
      }

      .topline-plan-score {
        @apply -mt-3;
      }
    }

    .header-plan-name > h1 {
      @apply text-xl font-semibold text-primary-900;
    }

    .header-details {
      @apply flex flex-wrap items-center gap-x-2;
    }
  }

  .header-banner {
    @apply flex h-[2.6rem] w-full items-center justify-center rounded-tl-quote rounded-tr-quote bg-sky-300;

    .banner-content {
      @apply flex items-center gap-x-2 text-lg font-bold;

      :deep(svg) {
        @apply text-blue-500;
      }
    }

    &.green {
      @apply bg-success-light;

      .banner-content {
        :deep(svg) {
          @apply !text-success-dark;
        }
      }
    }
  }
</style>
